/* SearchBar.css */
.search-bar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95vw;
}

.search-bar {
  display: flex;
  align-items: center;
  max-width: 600px;
  width: 100%;
  margin-bottom: 10px;
}

.search-input {
  flex: 1;
  padding: 10px;
  font-size: 16px;
  border-radius: 9px;
  border: 1px solid #ddd;
  background-color: #f0f0f0; /* Almost white */
  color: #333; /* Dark text for readability */
  box-sizing: border-box;
}

.buttons-container {
  display: flex;
  justify-content: space-between;
  width: 95vw;
  max-width: 600px;
  gap: 10px;
  flex-wrap: wrap; /* Allow wrapping on small screens */
}

.button {
  padding: 10px;
  font-size: 16px;
  font-weight: 600;
  border: none;
  border-radius: 6px;
  background-color: #858afa; /* Button color */
  color: white;
  cursor: pointer;
  box-sizing: border-box;
  flex: 1;
  width: 100%; /* Full width on mobile */
  max-width: none;
}

/* Responsive Styles */
@media (max-width: 600px) {
  .search-bar {
    flex-direction: column;
    align-items: stretch;
    width: 100%; /* 90% width on mobile */
  }

  .buttons-container {
    flex-direction: row;
    flex-wrap: nowrap;
  }

  .button {
    width: 100%; /* Full width on mobile */
    max-width: none;
  }
}
